import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()

    var default_opts = {
      plugins: ['restore_on_backspace', 'remove_button'],
      searchField: ['text', 'optgroup'],
      valueField: "id",
      allowEmptyOption: false,
      closeAfterSelect: true,
      showEmptyOptionInDropdown: true,
      emptyOptionLabel: true,
      hideSelected: true,
      placeholder: "Please Select"
    }

    $(".default-selectize").selectize(default_opts);

    var $doctrine_form = $("#doctrineForm")
    var $doctrine_content = $doctrine_form.find("input[name='content']");
    var $trix_content = $doctrine_form.find("trix-editor[placeholder='Doctrine Content']");

    var doctrine_content_suggestions_opts = {
      onChange: function (value) {
        if(value === null || value === undefined || value === "") {
          $doctrine_content.val("");
          $trix_content.val("");
        } else {
          $.get("/api/doctrines/" + value + ".json", {}, function (doctrine, status) {
            if (status === "success") {
              $doctrine_content.val(doctrine.content);
              $doctrine_form.find("input[name='headnote']").val(doctrine.headnote)
              $trix_content.val(doctrine.content);
            }
          });
        }
      }
    };

    var subject_ids_opts = {
      load: function (query, callback) {
        $.get("/api/subjects.json", { q: query, per_page: 100 }, function (data, status) {
          if (status === 'success') {
            callback(data);
          }
        })
      },
      onChange: function (value) {
        var selectize_options = []
        var $selectize = $("#doctrineForm").find("#doctine_content_suggestions").selectize($.extend({ options: selectize_options }, doctrine_content_suggestions_opts));

        if (value.length === 0) {
          if ($selectize.length) $selectize[0].selectize.clearOptions();
        } else {
          $.get("/api/doctrines/suggested_contents.json", { subject_ids: value }, function(data, status) {
            if (status === "success") {
              $.each(data, function (i, doctrine) {
                selectize_options.push({ id: doctrine.id, value: doctrine.content, text: doctrine.plain_content });
              })

              if ($selectize.length) $selectize[0].selectize.addOption(selectize_options);
            }
          });
        }

        if ($selectize.length) $selectize[0].selectize.refreshOptions();
      }
    };

    var $subject_ids_selectize = $(".subject-ids-selectize").selectize($.extend(subject_ids_opts, default_opts));

    var subjects_selectize_opts = {
      load: function (query, callback) {
        $.get("/api/subjects.json", { q: query, per_page: 100 }, function (data, status) {
          if (status === 'success') {
            callback(data);
          }
        })
      }
    }

    var $subjects_selectize = $(".subjects-selectize").selectize($.extend(subjects_selectize_opts, default_opts))

    $.get("/api/subjects.json", { per_page: 100 }, function (results, status) {
      if (status === 'success') {
        $.each(results, function (i, result) {
          if ($subject_ids_selectize.length) $subject_ids_selectize[0].selectize.addOption(result, true);
          if ($subjects_selectize.length) $subjects_selectize[0].selectize.addOption(result, true);
        })

        //this code will be autoFocus on the first selectize
        // if ($subject_ids_selectize.length) $subject_ids_selectize[0].selectize.refreshOptions();
        // if ($subjects_selectize.length) $subjects_selectize[0].selectize.refreshOptions();
      }
    });

    $("#doctine_content_suggestions").selectize($.extend(doctrine_content_suggestions_opts, default_opts));
  }
}
