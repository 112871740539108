import ApplicationController from './application_controller'
export default class extends ApplicationController {
  static targets = ["q", "document_ids", "annomark_ids", "phil_rep", "editor_notes"]

  connect () {
    super.connect()
  }

  searchDocuments () {
    this.stimulate("AnnotationReflex#render_document_search_results", { q: this.qTarget.value, page: 1 })
  }

  paginateDocuments () {
    this.stimulate("AnnotationReflex#render_document_search_results", { q: this.element.dataset["q"], page: this.element.dataset["page"] })
  }

  renderForm () {
    var $this = this, annotation_id = $this.element.dataset["annotationId"], doctrine_id = $this.element.dataset["doctrineId"], 
        document_title = "", document_ref_no = "", document_date_or_year = "", $modal = $("#annotationModal")

    $modal.find("#current_document_id").val($this.element.dataset["currentDocumentId"])
    if(annotation_id !== null && annotation_id !== undefined && annotation_id !== "") {
      $modal.find(".modal-title").text("Edit Annotation")
      $.get("/api/doctrines/" + doctrine_id + "/annotations/" + annotation_id + ".json", {}, function(result, status) {
        $modal.find("#annotation_id").val(annotation_id)
        $modal.find("#doctrine_id").val(doctrine_id)
        $modal.find("#document_id").val(result.document_id)
        $modal.find("#document_ids").val(result.citing_document_ids)
        $modal.find("#phil_rep").val(result.phil_rep)
        $modal.find("#document_title").val($this.element.dataset["documentTitle"])
        $modal.find("select[name='annomark_ids[]']")[0].selectize.setValue(result.annomark_ids)
        $modal.find(".trix-content").val(result.editor_notes)
      });
    } else {
      $modal.find(".modal-title").text("New Annotation")
      document_title = this.element.dataset["documentTitle"]
      document_ref_no = this.element.dataset["documentReferenceNumber"]
      document_date_or_year = this.element.dataset["documentDateOrYear"]
  
      $modal.find("#doctrine_id").val(doctrine_id)
      $modal.find("#document_title").val([document_title, document_ref_no, document_date_or_year].join(", "))
      $modal.find("#document_id").val(this.element.dataset["documentId"])
      $modal.find("#phil_rep").val(this.element.dataset["documentPhilRep"])
    }
  }

  addAnnotatedDocument () {
    var document_id = "", document_ref_no = "", document_date = "", document_title = "",
        $form = $("#annotationForm"), $doc_title = $form.find("trix-editor[placeholder='Document Title']"), 
        $citing_document_ids = $form.find("#document_ids")

    document_id = this.element.dataset["documentId"]
    document_ref_no = this.element.dataset["documentReferenceNumber"]
    document_date = this.element.dataset["documentDate"]
    document_title = this.element.dataset["documentTitle"]

    var document_ids = []

    if ($citing_document_ids.val() !== null && $citing_document_ids.val() !== undefined && $citing_document_ids.val() !== "") {
      document_ids = $citing_document_ids.val().split(",")
    }

    document_ids.push(document_id)
    $citing_document_ids.val(document_ids.join(","))

    var doc_title = $doc_title.val()
    $doc_title.val(doc_title + " citing " + [document_title, document_ref_no, document_date].join(", "))
  }

  save () {
    var $this = this, $form = $("#annotationForm"), annotation_id = $this.element.dataset["annotationId"], 
        jurisprudence_id = $this.element.dataset["jurisprudenceId"], doctrine_id = $this.element.dataset["doctrineId"]
    const params = { annomark_ids: $form.find("select[name='annomark_ids[]']").val(), document_id: $this.element.dataset["documentId"],
                     document_ids: $this.document_idsTarget.value || "", phil_rep: $this.phil_repTarget.value || "", 
                     editor_notes: $this.editor_notesTarget.value || "", content: $form.find("input[name='content']").val() }

    if (annotation_id !== null && annotation_id !== undefined && annotation_id !== "") {
      $.ajax({
        url: "/api/doctrines/" + doctrine_id + "/annotations/" + annotation_id,
        type: 'PUT',
        data: params,
        success: function() {
          $(".btn-close-x").trigger("click")
          $('.toast').addClass('bg-success').show();
          $( ".toast-body" ).html( "<span><b>Submitted Successfully</b></span>" );
          location.pathname = "/documents/" + jurisprudence_id + "/doctrines/" + doctrine_id
        },
        error: function() { 
          $(".btn-close-x").trigger("click");
          $('.toast').addClass('bg-danger').show();
          $( ".toast-body" ).html( "<span><b>Unexpected Error Problem Occurred</b></span>" );
        }
      })
    } else {
      $.post("/api/doctrines/" + doctrine_id + "/annotations", params, function(result, status) {
        if(status === "success") {
          $(".btn-close-x").trigger("click");
          $('.toast').addClass('bg-success').show();
          $( ".toast-body" ).html( "<span><b>Submitted Successfully</b></span>" );
          location.pathname = "/documents/" + jurisprudence_id + "/doctrines/" + doctrine_id
        } else {
          $(".btn-close-x").trigger("click");
          $('.toast').addClass('bg-danger').show();
          $( ".toast-body" ).html( "<span><b>Unexpected Error Problem Occurred</b></span>" );
        }
      })
    }
  }

  delete(ev) {
    var $this = this, doctrine_id = $this.element.dataset["doctrineId"], annotation_id = this.element.dataset["annotationId"],
        jurisprudence_id = $this.element.dataset["jurisprudenceId"]

    ev.preventDefault();
    const contrim_alert = confirm("Are you sure to delete this record?")
    if (contrim_alert) {
      $.ajax({
        url: "/api/doctrines/" + doctrine_id + "/annotations/" + annotation_id,
        type: 'DELETE',
        success: function() {
          $('.toast').addClass('bg-danger').show();
          $( ".toast-body" ).html( "<span><b>Deleted Successfully</b></span>" );
          location.pathname = "/documents/" + jurisprudence_id + "/doctrines/" + doctrine_id
        }
      })
    }
  }

  paginate () {
    this.stimulate("AnnotationReflex#render_annotations_views", { doctrine_id: this.element.dataset["doctrineId"], page: this.element.dataset["page"] })
  }
}
