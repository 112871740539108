import ApplicationController from './application_controller'
var annotations_attributes = []
export default class extends ApplicationController {
  static targets = ["input", "q", "document_id", "doctrine_id", "headnote", "content"]

  connect () {
    super.connect()
  }

  renderForm () {
    var document_id = this.element.dataset["documentId"], doctrine_id = this.element.dataset["doctrineId"],
        $modal = $("#doctrineModal"), $subject_ids_el = $modal.find(".subject-ids-selectize"), 
        $subject_ids_selectize = $subject_ids_el[0].selectize

    $modal.find("#document_id").val(document_id)

    if (doctrine_id !== null && doctrine_id !== undefined && doctrine_id !== "") {
      $modal.find("#doctine_content_suggestions_div").hide()
      $.get("/api/doctrines/" + doctrine_id + ".json", {}, function (doctrine, status) {
        $modal.find("#doctrine_id").val(doctrine.id)
        if (status === "success") {
          $.get("/api/subjects.json?doctrine_ids=" + doctrine.id, { per_page: 100 }, function (subjects, status) {
            if (status === "success") {
              $.each(subjects, function(i, subject) {
                $subject_ids_selectize.addOption(subject)
              })

              $subject_ids_selectize.refreshOptions()
              $subject_ids_selectize.setValue(doctrine.subject_ids, true)
              $subject_ids_selectize.blur()
              $subject_ids_selectize.close()
            }
          });

          $modal.find(".trix-content").val(doctrine.content)
          $modal.find("#headnote").val(doctrine.headnote)
        }
      })

      $modal.find(".modal-title").text("Update Doctrine")
      $(".selectize-dropdown").hide()
    } else {
      $modal.find("#doctine_content_suggestions_div").show()
      $modal.find(".modal-title").text("New Doctrine")
      $modal.find(".trix-content").val("")
      $subject_ids_selectize.setValue([])
      $modal.find("#doctrine_id").val('')
      $modal.find("#headnote").val('')
    }
  }

  save () {
    var $this = this, $form = $("#doctrineForm"), document_id = $this.document_idTarget.value, doctrine_id = $this.doctrine_idTarget.value
    var params = { subject_ids: $form.find("select[name='subject_ids[]']").val(), headnote: $this.headnoteTarget.value, 
                   content: $this.contentTarget.value }

    if (!params.subject_ids.length) {
      $form.find("#subjects-blank-message").show()
    }

    if (!params.headnote.length) {
      $form.find("#headnote-blank-message").show()
    }

    if (!params.content.length) {
      $form.find("#content-blank-message").show()
    }

    if (params.subject_ids.length && params.headnote.length && params.content.length) {
      if (doctrine_id !== null && doctrine_id !== undefined && doctrine_id !== "") {
        $.ajax({
          url: "/api/doctrines/" + doctrine_id,
          type: 'PUT',
          data: params,
          success: function() {
            $(".btn-close-x").trigger("click");
            $('.toast').addClass('bg-success').show();
            $( ".toast-body" ).html( "<span><b>Submitted Successfully</b></span>" );
            $('.toast').fadeOut(5000);
            location.pathname = "/documents/" + document_id + "/doctrines/" + doctrine_id
          },
          error: function() { 
            $(".btn-close-x").trigger("click");
            $('.toast').addClass('bg-danger').show();
            $('.toast').fadeOut(5000);
            $( ".toast-body" ).html( "<span><b>Unexpected Error Problem Occurred</b></span>" );
          }
        })
      } else {
        $.post("/api/doctrines", $.extend({ jurisprudence_ids: [document_id] }, params), function(doctrine, status) {
          if(status === 'success') {
            $('.toast').addClass('bg-success').show();
            $( ".toast-body" ).html( "<span><b>Doctrine was successfully saved</b></span>" );
            $('.toast').fadeOut(5000);

            if (annotations_attributes.length > 0) {
              $.each(annotations_attributes, function(index, annotation) {
                $.post("/api/doctrines/" + doctrine.id + "/annotations", annotation, function(result, status) {
                  if(status === 'success') {
                    $('.toast').addClass('bg-success').show();
                    $( ".toast-body" ).html( "<span><b>Case Lists was successfully saved</b></span>" );
                    $('.toast').fadeOut(5000);

                    if (index === annotations_attributes.length - 1) {
                      $(window).delay(5000).queue(function() {
                        location.pathname = "/documents/" + document_id + "/doctrines/" + doctrine.id
                      })
                    }
                  }
                })
              })
            } else {
              location.pathname = "/documents/" + document_id + "/doctrines/" + doctrine.id
            }
          } else {
            $('.toast').addClass('bg-danger').show();
            $( ".toast-body" ).html( "<span><b>Unexpected Error Problem Occurred</b></span>" );
            $('.toast').fadeOut(5000);
          }
        })
      }
    }
  }

  delete(ev) {
    var doctrine_id = this.element.dataset["doctrineId"], document_id = this.element.dataset["documentId"]

    ev.preventDefault();
    const contrim_alert = confirm("Are you sure to delete this record?")
    if (contrim_alert) {
      $.ajax({
        url: "/api/doctrines/" + doctrine_id,
        type: 'DELETE',
        success: function() {
          $('.toast').addClass('bg-danger').show();
          $( ".toast-body" ).html( "<span><b>Deleted Successfully</b></span>" );
          $('.toast').fadeOut(5000);
          location.pathname = "/documents/" + document_id
        },
        error: function () {
          $('.toast').addClass('bg-danger').show();
          $( ".toast-body" ).html( "<span><b>Unexpected Error Problem Occurred</b></span>" );
          $('.toast').fadeOut(5000);
        }
      })
    }
  }

  searchCitingDocuments () {
    this.stimulate("DoctrinesReflex#render_citing_documents_search_results", { q: this.qTarget.value, page: 1 })
  }

  paginateCitingDocuments () {
    this.stimulate("AnnotationReflex#render_citing_documents_search_results", { q: this.element.dataset["q"], page: this.element.dataset["page"] })
  }

  addCitingDocument () {
    var document_id = "", document_ref_no = "", document_date = "", document_title = "",
        $modal = $("#annotationModal"), $doc_title = $modal.find("trix-editor[placeholder='Document Title']"), 
        $citing_document_ids = $modal.find("#document_ids")

    document_id = this.element.dataset["documentId"]
    document_ref_no = this.element.dataset["documentReferenceNumber"]
    document_date = this.element.dataset["documentDate"]
    document_title = this.element.dataset["documentTitle"]

    var document_ids = []

    if ($citing_document_ids.val() !== null && $citing_document_ids.val() !== undefined && $citing_document_ids.val() !== "") {
      document_ids = $citing_document_ids.val().split(",")
    }

    document_ids.push(document_id)
    $citing_document_ids.val(document_ids.join(","))

    var doc_title = $doc_title.val()
    $doc_title.val(doc_title + " citing " + [document_title, document_ref_no, document_date].join(", "))
  }

  removeAnnotation () {
    var index = this.element.dataset["annotationsAttributesIndex"]
    const contrim_alert = confirm("Are you sure that you want to remove this Case List?")

    if (contrim_alert) {
      annotations_attributes.splice(index, 1)
      $('.toast').addClass('bg-success').show();
      $( ".toast-body" ).html( "<span><b>Case List is successfully removed.</b></span>" );
      $('.toast').fadeOut(5000)
      this.stimulate("DoctrinesReflex#render_doctrine_form_annotations_view", annotations_attributes)
    }
  }

  saveAnnotation () {
    var $modal = $("#annotationModal"), annotaitons_attributes_index = $modal.find("#annotaitons_attributes_index").val()
    var params = { document_id: $modal.find("#document_id").val(), document_ids: $modal.find("#document_ids").val().split(","), 
                   content: $modal.find("input[name='document_title']").val(), phil_rep: $modal.find("#phil_rep").val(),
                   editor_notes: $modal.find("input[name='editor_notes']").val(), annomark_ids: $modal.find("select[name='annomark_ids[]']").val() }

    if (annotaitons_attributes_index.length > 0) {
      annotations_attributes.splice(annotaitons_attributes_index, 1, params)
      $( ".toast-body" ).html( "<span><b>Case List is successfully updated.</b></span>" );
    } else {
      annotations_attributes.push(params)
      $( ".toast-body" ).html( "<span><b>Case List is successfully added.</b></span>" );
    }
    
    $(".btn-close-x").trigger("click");
    $('.toast').addClass('bg-success').show();
    $('.toast').fadeOut(5000);
    $modal.find("select[name='annomark_ids[]']")[0].selectize.setValue([])
    this.stimulate("DoctrinesReflex#render_doctrine_form_annotations_view", annotations_attributes)
    this.stimulate("DoctrinesReflex#render_default_citing_documents")
    this.stimulate("DoctrinesReflex#render_default_case_lists")
  }

  newAnnotation () {
    var document_title = "", document_ref_no = "", document_date_or_year = "", $modal = $("#annotationModal")

    $modal.find(".modal-title").text("New Annotation")
    document_title = this.element.dataset["documentTitle"]
    document_ref_no = this.element.dataset["documentReferenceNumber"]
    document_date_or_year = this.element.dataset["documentDateOrYear"]

    $modal.find("select[name='annomark_ids[]']").val([])
    $modal.find("#doctrine_id").val(null)
    $modal.find("trix-editor[placeholder='Document Title']").val([document_title, document_ref_no, document_date_or_year].join(", "))
    $modal.find("#document_id").val(this.element.dataset["documentId"])
    $modal.find("#phil_rep").val(this.element.dataset["documentPhilRep"])
  }

  editAnnotation () {
    var $modal = $("#annotationModal"), annotations_attributes_index = this.element.dataset["annotationsAttributesIndex"], 
        annotation = annotations_attributes[annotations_attributes_index]

    $modal.find(".modal-title").text("Edit Annotation")

    $modal.find("select[name='annomark_ids[]']")[0].selectize.setValue(annotation.annomark_ids)
    $modal.find("trix-editor[placeholder='Document Title']").val(annotation.content)
    $modal.find("#document_id").val(annotation.document_id)
    $modal.find("#annotaitons_attributes_index")[0].selectize.setValue(annotations_attributes_index)

    if (annotation.document_ids.length) {
      $modal.find("#document_ids").val(annotation.document_ids.join(","))
    }

    $modal.find("#phil_rep").val(annotation.phil_rep)
  }
}
