import ApplicationController from './application_controller'
export default class extends ApplicationController {
  connect () {
    super.connect()
  }

  loadSubjectsAccordion () {
    var $this = this, params = { per_page: 10000 }, parent_id = $this.element.dataset["subjectParentId"],
        opts = { parent_accordion_id: $this.element.dataset["accordionId"], accordionTarget: $this.element.dataset["accordionTarget"],
                 accordionFor: $this.element.dataset["accordionFor"] }

    if (parent_id !== null && parent_id !== undefined && parent_id !== "") {
      params.parent_id = parent_id
    } else {
      params.is_root = true
    }

    if ($(opts.accordionTarget).find(".accordion-flush").length === 0) {
      $.get("/api/subjects.json", params, function (search_results, status) {
        if (status === "success") {
          $this.stimulate("SubjectsReflex#render_subjects_accordion", search_results, opts)
        }
      })
    }
  }

  loadSubjectsOffcanvasAccordion() {
    var $this = this, params = { per_page: 10000 }, parent_id = $this.element.dataset["subjectParentId"],
        opts = { accordionTarget: $this.element.dataset["accordionTarget"] }

    if (parent_id !== null && parent_id !== undefined && parent_id !== "") {
      params.parent_id = parent_id
    } else {
      params.is_root = true
    }

    if ($(opts.accordionTarget).find(".accordion-flush").length === 0) {
      $.get("/api/subjects.json", params, function (search_results, status) {
        if (status === "success") {
          $this.stimulate("SubjectsReflex#render_subjects_offcanvas_accordion", search_results, opts)
        }
      })
    }
  }

  loadDoctrinesOffcanvasAccordion() {
    var $this = this, params = { per_page: 10000 }, parent_id = $this.element.dataset["subjectParentId"],
        opts = { accordionTarget: $this.element.dataset["accordionTarget"] }

    if (parent_id !== null && parent_id !== undefined && parent_id !== "") {
      params.parent_id = parent_id
    } else {
      params.is_root = true
    }

    if ($(opts.accordionTarget).find(".accordion-flush").length === 0) {
      $.get("/api/subjects.json", params, function (search_results, status) {
        if (status === "success") {
          $this.stimulate("SubjectsReflex#render_doctrines_offcanvas_accordion", search_results, opts)
        }
      })
    }
  }
}
