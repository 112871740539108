// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import '../src/application.scss'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/js/all.min";
import "@fortawesome/fontawesome-free/css/all.min";
import "moment";
import "chosen-js";
import 'trix/dist/trix.css';
import 'trix/dist/trix.js';
import "cable_ready";
import "flatpickr/dist/flatpickr";

window.jQuery = $;
window.$ = $;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

$(document).on("ready turbolinks:load", function () {
  $('#toggleSideNav').on('click', function () {
    $('#sidenav').toggleClass('active');
  });

  $("#toast-close").click(function(){
    $('.toast').hide();
  }); 
  
  $(".spinner-border").hide();
  
  var default_config = { altFormat: "m/d/Y", altInput: true, enableTime: false, dateFormat: "Y-m-d", isMobile: true };
  $('.default-flatpickr').flatpickr(default_config);

  if ($.fn.footnotes) {
    $('#documentTabContent').footnotes()
  }

  $(document).on('click', 'a[href="#footnotes"]', function(event){
    $(this).each(function(){
      var a, b, c, d;
      a = $(this), b = a.attr('href'), c = a.text(), d = '#footnote' + c
      a.attr('href', d), a.attr('id', 'fn' + c)
      a.trigger('click')
    })
    event.preventDefault();
  }).on('click', 'a[href^="#footnote"], a[href^="#fn"]', function(event){
    var a, b;
    a = $(this), b = $(a.attr('href'))
    if(b.length<=0)return
    event.preventDefault()
    document.getElementById(b[0].id).scrollIntoView( {behavior: "smooth", duration: 700 })
  })
});

import "channels"
import "controllers"
import "./application/footnotes"
