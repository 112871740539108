import ApplicationController from './application_controller'
export default class extends ApplicationController {
  static targets = ["q", "headnote", "date_created"]

  connect () {
    super.connect()
  }
  
  search () {
    var $search_box = $('.advanced-search-box'), 
        search_params = { q: this.qTarget.value, headnote: this.headnoteTarget.value, 
                          subject_ids: $search_box.find("select[name='subject_ids[]']").val(),
                          user_ids: $search_box.find("select[name='user_ids[]']").val() }
    
    this.stimulate("ReportsReflex#render_search_results", search_params)
  }
}
