import ApplicationController from './application_controller'
export default class extends ApplicationController {
  static targets = ["input", "citation_finder"]

  connect () {
    super.connect()
  }

  search () {
    this.stimulate("DocumentReflex#render_case_lists_search_results", 
                   { q: this.inputTarget.value, citation_finder: this.citation_finderTarget.value, page: 1 },
                     this.element.dataset["doctrineId"], this.element.dataset["jurisprudenceId"])
  }

  searchDoctrineFormCaseLists () {
    this.stimulate("DocumentReflex#render_doctrine_form_case_lists", 
                   { q: this.inputTarget.value, citation_finder: this.citation_finderTarget.value, page: 1 })
  }

  paginateDoctrineFormCaseLists () {
    this.stimulate("DocumentReflex#render_doctrine_form_case_lists", 
                   { q: this.element.dataset["q"], citation_finder: this.element.dataset["citationFinder"], 
                     page: this.element.dataset["page"] })
  }

  paginate () {
    this.stimulate("DocumentReflex#render_case_lists_search_results", 
                   { q: this.element.dataset["q"], citation_finder: this.element.dataset["citationFinder"], 
                     page: this.element.dataset["page"] },
                     this.element.dataset["doctrineId"], this.element.dataset["jurisprudenceId"])
  }

  loadYears () {
    var $this = this
    $.get("/api/jurisprudences/years.json", {}, function (data, status) {
      if (status === "success") {
        $this.stimulate("DocumentReflex#render_years", data)
      }
    });
  }
}
